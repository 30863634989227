export const AIRCRAFT_PORTFOLIO_SEARCH = "AIRCRAFT_PORTFOLIO/SEARCH";

export const AIRCRAFT_PORTFOLIO_RETRIEVE = "AIRCRAFT_PORTFOLIO/RETRIEVE";
export const AIRCRAFT_PORTFOLIO_RETRIEVE_PENDING = "AIRCRAFT_PORTFOLIO/RETRIEVE_PENDING";
export const AIRCRAFT_PORTFOLIO_RETRIEVE_FULFILLED = "AIRCRAFT_PORTFOLIO/RETRIEVE_FULFILLED";
export const AIRCRAFT_PORTFOLIO_RETRIEVE_REJECTED = "AIRCRAFT_PORTFOLIO/RETRIEVE_REJECTED";

export const AIRCRAFT_PORTFOLIO_CREATE = "AIRCRAFT_PORTFOLIO/CREATE";
export const AIRCRAFT_PORTFOLIO_CREATE_FORM_NAME = "new-aircraft-portfolio";

export const AIRCRAFT_PORTFOLIO_UPDATE = "AIRCRAFT_PORTFOLIO/UPDATE";
export const AIRCRAFT_PORTFOLIO_UPDATE_FULFILLED = "AIRCRAFT_PORTFOLIO/UPDATE_FULFILLED";
export const AIRCRAFT_PORTFOLIO_UPDATE_FORM_NAME = "update-aircraft-portfolio";

export const AIRCRAFT_PORTFOLIO_DESTROY = "AIRCRAFT_PORTFOLIO/DESTROY";
export const AIRCRAFT_PORTFOLIO_DESTROY_FORM_NAME = "delete-aircraft-portfolio";
