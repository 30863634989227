export const AMPL_HOME_BUTTON_CLICKED = "home_button_clicked";
export const AMPL_TRANSACTION_BUTTON_CLICKED = "transaction_button_clicked";
export const AMPL_VALUATION_BUTTON_CLICKED = "valuation_button_clicked";
export const AMPL_PORTFOLIO_BUTTON_CLICKED = "portfolio_button_clicked";
export const AMPL_GLOSSARY_BUTTON_CLICKED = "glossary_button_clicked";
export const AMPL_METHODOLOGY_BUTTON_CLICKED = "methodology_button_clicked";

export const AMPL_SEARCH_INDIVIDUAL_BUTTON_CLICKED = "search_individual_button_clicked";
export const AMPL_SEARCH_TRANSACTION_BUTTON_CLICKED = "search_transaction_button_clicked";
export const AMPL_BUILD_MANAGE_PORTFOLIO_CLICKED = "build_manage_portfolio_button_clicked";

export const AMPL_TRANSACTION_LOOKUP_CLICKED = "transaction_lookup_clicked";
export const AMPL_TRANSACTION_RESET_CLICKED = "transaction_reset_clicked";

export const AMPL_GET_VALUATIONS_CLICKED = "valuation_get_valuations_clicked";
export const AMPL_VALUATIONS_RESET_CLICKED = "valuations_reset_clicked";
export const AMPL_VALUATIONS_DOWNLOAD_EXCEL_CLICKED = "valuations_download_excel_clicked";
export const AMPL_VALUATIONS_EDIT_CONFIGURATION_CLICKED = "valuations_edit_configuration_clicked";

export const AMPL_PORTFOLIO_OVERVIEW_NEW_PORTFOLIO_CLICKED = "portfolio_overview_new_portfolio_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_EMAIL_US_CLICKED = "portfolio_overview_email_us_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_VIEW_PORTFOLIO_CLICKED = "portfolio_overview_view_portfolio_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_UPDATE_PORTFOLIO_CLICKED = "portfolio_overview_update_portfolio_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_UPDATE_PORTFOLIO_UPDATE_CLICKED =
    "portfolio_overview_update_portfolio_update_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_DELETE_PORTFOLIO_CLICKED = "portfolio_overview_delete_portfolio_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_DELETE_PORTFOLIO_DELETE_CLICKED =
    "portfolio_overview_delete_portfolio_delete_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_DELETE_PORTFOLIO_CANCEL_CLICKED =
    "portfolio_overview_delete_portfolio_cancel_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_CREATE_NEW_PORTFOLIO_CREATE_CLICKED =
    "portfolio_overview_create_new_portfolio_create_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_CREATE_NEW_PORTFOLIO_RESET_CLICKED =
    "portfolio_overview_create_new_portfolio_reset_clicked";
export const AMPL_PORTFOLIO_OVERVIEW_CREATE_NEW_PORTFOLIO_SHARE_CREATE_CLICKED =
    "portfolio_overview_create_new_portfolio_share_create_clicked";
export const AMPL_PORTFOLIO_DETAILED_BACK_TO_PORTFOLIO_CLICKED = "portfolio_detailed_back_to_your_portfolio_clicked";
export const AMPL_PORTFOLIO_DETAILED_ADD_AIRCRAFT_CLICKED = "portfolio_detailed_add_aircraft_clicked";
export const AMPL_PORTFOLIO_DETAILED_ADD_SHARE_CLICKED = "portfolio_detailed_add_share_clicked";
export const AMPL_PORTFOLIO_DETAILED_ADD_AIRCRAFT_CREATE_CLICKED = "portfolio_detailed_add_aircraft_create_clicked";
export const AMPL_PORTFOLIO_DETAILED_ADD_AIRCRAFT_RESET_CLICKED = "portfolio_detailed_add_aircraft_reset_clicked";
export const AMPL_PORTFOLIO_DETAILED_EXPORT_PORTFOLIO_CLICKED = "portfolio_detailed_export_portfolio_clicked";
export const AMPL_PORTFOLIO_DETAILED_EDIT_PORTFOLIO_CLICKED = "portfolio_detailed_edit_portfolio_clicked";
export const AMPL_PORTFOLIO_DETAILED_EDIT_PORTFOLIO_UPDATE_CLICKED = "portfolio_detailed_edit_portfolio_update_clicked";
export const AMPL_PORTFOLIO_DETAILED_EDIT_PORTFOLIO_RESET_CLICKED = "portfolio_detailed_edit_portfolio_reset_clicked";
export const AMPL_PORTFOLIO_DETAILED_DELETE_PORTFOLIO_CLICKED = "portfolio_detailed_delete_portfolio_clicked";
export const AMPL_PORTFOLIO_DETAILED_DELETE_PORTFOLIO_DELETE_CLICKED =
    "portfolio_detailed_delete_portfolio_delete_clicked";
export const AMPL_PORTFOLIO_DETAILED_DELETE_PORTFOLIO_CANCEL_CLICKED =
    "portfolio_detailed_delete_portfolio_cancel_clicked";
export const AMPL_PORTFOLIO_DETAILED_UPDATE_ENTRY_CLICKED = "portfolio_detailed_update_entry_clicked";
export const AMPL_PORTFOLIO_DETAILED_DELETE_ENTRY_CLICKED = "portfolio_detailed_delete_entry_clicked";
export const AMPL_PORTFOLIO_DETAILED_DELETE_SHARE_CLICKED = "portfolio_detailed_delete_share_clicked";
